import React, { useEffect, useState, useRef } from "react";
import style from "./videoDetails.module.scss";
import {
  back_button,
  crossButton,
  grayHeart,
  redHeart,
  videoListIcon,
} from "../../../assets/images/index";
import Comment from "../../../components/comments/Comment";
import { useUser } from "../../../state/UserContext";
import { useFetcher, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { robo } from "../../../assets/images";
import Hls from "hls.js";
import api from  "../../../Intercepters/AuthIntercepter"

function VideoDetails() {
  const videoId = useParams().id;
  const navigate = useNavigate();
  const [isFollowed, setIsFollowed] = useState(false);
  const [video, setVideo] = useState(null);
  const [views, setViews] = useState(0);
  const [productFollowers, setProductFollowers] = useState([]);
  const [businessOwnerVideos, setBusinessOwnerVideos] = useState([]);
  const videoRef = useRef(null);
  const { userData } = useUser();

  const isBusinessOwner = JSON.parse(
    window.localStorage.getItem("isBusinessOwner")
  );

  const getBusinessOwnerVideos = async () => {
    if (isBusinessOwner) {
      const res = await api.get(
        `${process.env.REACT_APP_API_URL}/videos/video-by-business-owner/${userData._id}`
      );
      const response = await res.json();
      setBusinessOwnerVideos(response.map((video) => video.videoId));
    }
  };

  const formatDate = (inputdate) => {
    const date = new Date(inputdate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    return `${year}-${month}-${day}`;
  };

  const getVideoInformation = async () => {
    try {
      const res = await api.get(
        `/videos/video-by-id/${videoId}`
      );
    //  const response = await res.json();
      if (res) {
        setVideo(res.data);
        if (Hls.isSupported() && videoRef.current) {
          console.log("cond 3");
          const newHls = new Hls();
          newHls.loadSource(res.data.videoURL);
          newHls.attachMedia(videoRef.current);
          // newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          //   videoRef.current.play();
          // });
        }
        // else if (videoRef.current?.canPlayType("application/vnd.apple.mpegurl")) {
        //   videoRef.current.src = `${process.env.REACT_APP_API_URL}/videos/${video._id}/output.m3u8`;
        //   videoRef.current.addEventListener("loadedmetadata", () => {
        //     videoRef.current.play();
        //   });
        // }
        return res.data;
      }
    } catch (error) {}
  };

  useEffect(() => {
    getVideoInformation();
  }, []);

  useEffect(() => {}, [video]);

  const getProductInformation = async () => {
    try {
      const response = await api.get(
        `/products/${video?.productId}`
      );
      if (response) return response.data;
    } catch (error) {
      return null;
    }
  };

  const followProduct = async () => {
    try {
      const response = await api.post(
        `/products/add-follow`,
        { userId: userData._id, productId: video?.product._id }
      );
      if (response.status === 201) {
        setIsFollowed(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeFollowProduct = async () => {
    try {
      const response = await api.post(
        `/products/remove-follow`,
        { userId: userData._id, productId: video?.product._id }
      );
      if (response.status === 201) {
        setIsFollowed(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (video?.product.followers.includes(userData._id)) {
      setIsFollowed(true);
    } else {
      setIsFollowed(false);
    }
  }, [video?.product?.followers]);

  const handleFollow = async () => {
    if (video?.product.followers.includes(userData._id)) {
      await removeFollowProduct();
    } else {
      await followProduct();
    }
  };

  const closeHandle = () => {
    const activeItem = localStorage.getItem("activeItem");
    if (activeItem === "My Space") navigate("/my-space");
    else navigate("/");
  };

  //const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState([]);

  useEffect(() => {
    if (video) {
      setLikes(video?.likes || []);
      //setIsLiked(video?.likes?.includes(userData?._id));
    }
  }, [video]);




  const handleLike= async () => {
    try {
      const response = await api.put(
        `${process.env.REACT_APP_API_URL}/videos/Mobile_IOS/likeVideo/${videoId}`,{userId: userData?._id});
      setLikes(response.data.likes);
    } catch (error) {}
  };



  return (
    <div className={style["wrapper"]}>
      <div className={style["close-button-container"]}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={closeHandle}
        >
          <img
            src={back_button}
            alt="close"
            className={style["close-button"]}
          />{" "}
          <span style={{ fontWeight: "500", fontSize: "16px" }}>Back</span>
        </div>
      </div>
      <div className={style["video-page-container"]}>
        <div className={style["section-1"]}>
          <div className={style["video-container"]}>
            <video
              controls
              //src={video?.videoURL}
              ref={videoRef}
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className={style["section-2"]}>
          <div className={style["details-container"]}>
            <div className={style["details-video"]}>
              <Link
                key={video?.productId}
                className={style.product}
                to={`/product-details/${video?.product?._id}`}
              >
                {video?.product?.image ? (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/${video?.product?.image}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = robo;
                    }}
                    alt="product-logo"
                  ></img>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                  ></svg>
                )}
              </Link>
              <div className={style["product-information"]}>
                <Link
                  key={video?.product?._id}
                  className={style.product}
                  to={`/product-details/${video?.product?._id}`}
                >
                  <span className={style["product-name"]}>
                    {video?.product?.name}
                  </span>{" "}
                </Link>
                <p>
                  <span style={{ color: " #F6552DB5" }}>{video?.category}</span>{" "}
                  {views} views. {formatDate(video?.created_at)}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: "18px",
                gap: "10px",
              }}
            >
              {!businessOwnerVideos.includes(videoId) && (
                <button
                  className={style["follow-button"]}
                  onClick={handleFollow}
                >
                  {isFollowed
                    ? "Followed"
                    : productFollowers.includes(userData._id)
                    ? "Followed"
                    : "Follow"}
                </button>
              )}
              <span
                style={{
                  fontWeight: "400",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <span>{likes.length} </span>
                <button>
                  <img
                    src={likes?.includes(userData?._id) ? redHeart : grayHeart}
                    //src={isLiked ? redHeart : grayHeart}
                    onClick={handleLike}
                    alt="like button"
                    style={{
                      width: "20px",
                      height: "20px",
                      minWidth: "20px",
                      minHeight: "20px",
                    }}
                  />
                </button>
              </span>
            </div>
          </div>
          <span style={{ textDecoration: "underline" }}>Comments</span>

          <Comment videoId={videoId} productId={video?.product?._id}></Comment>
        </div>
      </div>
    </div>
  );
}

export default VideoDetails;
