import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = localStorage.getItem("token");
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    console.log("success response");
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.localStorage.clear();
      window.location.href = "/login";
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);

export default api;
