import React, { useState } from "react";
import { toast } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { google, img_right, login_ellipse, logo } from "../../../assets/images";
import PrimaryButton from "../../../components/primary-button/PrimaryButton";
import {
  TextField,
  InputAdornment,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import { useUser } from "../../../state/UserContext";
import { useLocation } from "react-router-dom";
import style from "./login.module.scss";
import { Helmet } from "react-helmet-async";
import api  from "../../../Intercepters/AuthIntercepter"

const LoginPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const location = useLocation();
  const { setUser } = useUser();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAuthGoogle = async () => {
    try {
      window.open(
        `${process.env.REACT_APP_API_URL}/auth/google/callback`,
        "_self"
      );
    } catch (error) {}
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#E86747",
      },
    },
  });

  const loginUser = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage("Email and password fields cannot be empty.");
      setOpenSnackbar(true);
      return;
    }
    try {
      const { data } = await api.post(
        `/auth/login`,
        {
          email,
          password,
        }
      );
      const { user, token } = data;
      setUser(data);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("isBusinessOwner", false);
      window.location.href = "/";
    } catch (err) {
      const errorResponse = err.response;
      const errorMsg =
        errorResponse && errorResponse.data && errorResponse.data.msg
          ? errorResponse.data.msg
          : "An error occurred";
      setErrorMessage(errorMsg);
      setOpenSnackbar(true);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          Sign In to Recolyse | Access AI-Powered Solutions’ Recommendations
        </title>
        <meta
          name="description"
          content="Sign in or create an account on Recolyse to start exploring AI-powered product recommendations from innovating startups."
        />
      </Helmet>
      <div className={style["login-page"]}>
        <div className={style["login-cont"]}>
          <div className={style["login-container"]}>
            <img
              src={login_ellipse}
              alt="ellipse"
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
              }}
            />
            <div className={style["login-logo"]}>
              <img onClick={() => navigate("/")} src={logo} alt="logo" />
            </div>
            <section className={style["login-section"]}>
              <div className={style["login-content"]}>
                <h1>Login to your account!</h1>
                <div
                  style={{ display: "flex", gap: "20px", marginTop: "30px" }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    marginTop: "30px",
                  }}
                >
                  <form onSubmit={loginUser} style={{ width: "100%" }}>
                    <ThemeProvider theme={theme}>
                      <div style={{ width: "100%" }}>
                        <label className={style["input-label"]}>
                          Email Adress
                        </label>
                        <TextField
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          id="outlined-basic"
                          variant="outlined"
                          sx={{
                            mt: 1,
                            color: "#E86747",
                            width: "100%",
                            marginBottom: "10px",
                          }}
                          color="primary"
                          type="email"
                        />
                      </div>
                      <div style={{ marginTop: "8px" }}>
                        <label className={style["input-label"]}>Password</label>
                        <TextField
                          variant="outlined"
                          sx={{ mt: 1, color: "#E86747", width: "100%" }}
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label={
                                    showPassword
                                      ? "Hide password"
                                      : "Show password"
                                  }
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </ThemeProvider>

                    {/* <div
                    style={{
                      marginTop: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        sx={{
                          color: "#E86747",
                          "&.Mui-checked": {
                            color: "#E86747",
                          },
                        }}
                      />
                      <span className={style["remember-me"]}>Remember me</span>
                    </div>

                    <div className={style["forget-password"]}>
                      Forget password?
                    </div>
                  </div> */}
                    <div style={{ marginTop: "35px" }}>
                      <PrimaryButton
                        type="submit"
                        disabled={!email || !password}
                        text="Login to continue"
                        full="w-full"
                      />
                    </div>
                  </form>

                  {/* Go to Sign Up */}
                  <div className={style["have-account"]}>
                    <p>Don’t have an account ?</p>
                    <button onClick={() => navigate("/signup")}>Sign up</button>
                  </div>

                  {/* Login buttons */}
                  <div className={style["google"]}>
                    <button onClick={handleAuthGoogle}>
                      <img src={google} alt="google" />
                      <span style={{ fontSize: "14px", fontWeight: "500" }}>
                        <span
                          style={{
                            display: "none",
                          }}
                          className={style["register-text"]}
                        >
                          Login with{" "}
                        </span>
                        Google
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* Right Image */}
          <div className={style["login-right"]}>
            <img
              src={img_right}
              alt="background right"
              className={style["login-img"]}
              // className="hidden md:flex object-cover h-screen "
            />
          </div>
        </div>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default LoginPage;
