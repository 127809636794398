import React, { useState, useContext, useEffect } from "react";
import { Autocomplete, Modal, TextField } from "@mui/material";
import style from "./createProduct.module.scss";
import { Context, useUser } from "../../state/UserContext";
import { useNavigate } from "react-router-dom";
import { importImage } from "../../assets/images";
import api from "../../Intercepters/AuthIntercepter";

const categories = [
  "Influencer marketing",
  "Productivity",
  "Community",
  "SEO",
  "Spotify",
  "Data",
  "Advertising",
  "SaaS",
  "pitch",
];

export default function CreateProductModal({ open, onClose }) {
  const [productName, setProductName] = useState("");
  const [tagline, setTagline] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [category, setCategory] = useState("");
  const [pricing, setPricing] = useState("");
  const [productImage, setProductImage] = useState(null);
  const [productImagePath, setProductImagePath] = useState("");
  const [links, setLinks] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const { userProductList, setUserProductList } = useContext(Context);

  const { newProduct, setNewProduct, setProductList, productList } =
    useContext(Context);
  const { userData } = useUser();
  const navigate = useNavigate();

  const resetForm = () => {
    setProductName("");
    setTagline("");
    setWebsiteLink("");
    setDescription("");
    setCategoryName("");
    setCategory("");
    setPricing("");
    setProductImage(null);
    setProductImagePath("");
    setLinks({});
    setFormErrors({});
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleChange = (event, newValue) => {
    setCategoryName(newValue);
    setCategory(newValue);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const objectUrl = URL.createObjectURL(file);
      console.log("Image preview URL:", objectUrl);
      setProductImagePath(file);
      setProductImage(objectUrl);

      return () => URL.revokeObjectURL(objectUrl); // Clean up after the image is no longer needed
    }
  };

  const [disabled, setDisabled] = useState(false);

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    console.log("Form submitted");

    const errors = {};

    if (!category) errors.category = "Category is required";
    if (!websiteLink) errors.websiteLink = "Website link is required";
    if (!productName) errors.productName = "Product name is required";
    if (!description) errors.description = "Description is required";
    if (!productImagePath) errors.productImage = "Image is required";

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        if (!userData?._id) {
          console.error("User is not authenticated.");
          return;
        }

        setDisabled(true);

        const formData = new FormData();
        formData.append("name", productName);
        formData.append("categories", category);
        formData.append("description", description);
        formData.append("website_link", websiteLink);
        formData.append("instagram_link", links["Instagram Link"] || "");
        formData.append("facebook_link", links["Facebook Link"] || "");
        formData.append("playstore_link", links["Play Store Link"] || "");
        formData.append("appstore_link", links["App Store Link"] || "");
        formData.append(
          "price",
          isNaN(parseInt(pricing)) ? 0 : parseInt(pricing)
        );
        formData.append("image", productImagePath);
        formData.append("pictures", []);
        formData.append("business_owner", userData._id);
        for (let [key, value] of formData.entries()) {
          console.log(key, value);
        }

        const response = await api.post(
          `/products/create/${userData._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Make sure to set the correct content type for form data
            },
          }
        );
        setProductList([...productList, response.data]);
        setNewProduct(newProduct + 1);
        setUserProductList((prev) => [...prev, newProduct]);
        navigate("/my-space");
        handleClose();
      } catch (error) {
        console.error("Error creating product:", error);
        if (error.response && error.response.status === 403) {
          console.error(
            "Unauthorized: User does not have permission to create a product."
          );
        }
      } finally {
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose} className={style.modal}>
      <div className={style["modal-content"]}>
        <div className={style["product-info"]}>
          <h1 className={style.header}>Tell us more about the product</h1>
          <div className={style.subheader}>
            We’ll need its name, tagline, links, topics, and description.
          </div>
          <form className={style.form} onSubmit={handleCreateProduct}>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Product Name *</div>
              <input
                className={style.input}
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
              {formErrors.productName && (
                <span className={style.error}>{formErrors.productName}</span>
              )}
            </div>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Tagline</div>
              <input
                className={style.input}
                value={tagline}
                onChange={(e) => setTagline(e.target.value)}
              />
            </div>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Website Link *</div>
              <input
                className={style.input}
                placeholder="www.website.com"
                value={websiteLink}
                onChange={(e) => setWebsiteLink(e.target.value)}
                type="url"
                required
              />
              {formErrors.websiteLink && (
                <span className={style.error}>{formErrors.websiteLink}</span>
              )}
            </div>
            <hr className={style.hr} />
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Product Logo *</div>
              <img
                src={productImage ? productImage : importImage}
                className={style["product-image"]}
                alt="Product"
              />
              <div className={style.input}>
                <input
                  type="file"
                  id="product-image-upload"
                  className={style.hiddenFileInput}
                  onChange={handleImageChange}
                />
                <div>
                  <label
                    htmlFor="product-image-upload"
                    className={style.labelContainer}
                  >
                    <span className={style.uploadLabel}> Click to upload </span>
                    <span className={style.image_size}>
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-5px",
                marginBottom: "10px",
              }}
            >
              <div></div>
              <span style={{ color: "red" }}>
                {formErrors.productImage && (
                  <span className={style.error}>{formErrors.productImage}</span>
                )}
              </span>
            </div>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Description *</div>
              <textarea
                className={style.input}
                rows="4"
                placeholder="Add a short description to your product"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              ></textarea>
              {formErrors.description && (
                <span className={style.error}>{formErrors.description}</span>
              )}
            </div>
            <hr className={style.hr} />
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Category *</div>
              <Autocomplete
                id="demo-multiple-name"
                className={style["auto-complete"]}
                value={categoryName}
                onChange={handleChange}
                options={categories}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                required
                isOptionEqualToValue={(option, value) =>
                  option === value || value === ""
                }
                sx={{ width: "100%" }}
              />
              {formErrors.category && (
                <span className={style.error}>{formErrors.category}</span>
              )}
            </div>
            <div className={style["form-inputs"]}>
              <div className={style["form-inputs-label"]}>Pricing</div>
              <input
                type="number"
                className={style.input}
                value={pricing}
                onChange={(e) => setPricing(e.target.value)}
              />
            </div>
            <div className={style.btns}>
              <button
                className={style.cancel}
                type="button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button className={style.next} type="submit" disabled={disabled}>
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
